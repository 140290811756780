import * as React from "react"
import MainLayout from '../layouts/main'
import {useContext} from "react";
import GlobalContext from "../context/GlobalContext";
import {graphql, useStaticQuery} from "gatsby";
import Breadcrumps from "../components/Breadcrumbs";
import Partners from "./home/partners";
import ContributiFiscali from "./home/contributi-fiscali";
import Help from "./home/help";
import HeroPage from "../components/HeroPage";
import SeoComponent from "../layouts/partials/SeoComponent";

const items = [
    { title: 'Chi siamo' }
]
const About = ({pageContext}) => {
    const gContext = useContext(GlobalContext);
    const allImagesQuery = graphql`
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(dettaglio)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]       
                        placeholder: NONE        
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    return (
        <MainLayout pageContext={pageContext}>
            <HeroPage
                image={gContext.getImageFromName(images, 'GIBUS_tendaggi.jpg')}
                imageAlt={'GIBUS_tendaggi.jpg'}
                title={'Chi siamo'}
                overlay={true}
            />
            <Breadcrumps  items={items}/>

            <div className="max-w-[1400px] mx-auto py-16 px-4 sm:px-6 lg:px-8 relative w-full">
                <h2 className="title-section text-center flex flex-col items-center justify-center">
                    {gContext.t('Tappezzeria Orsilli')}
                    <p className="text-base sm:text-xl mt-1 font-normal">Dal 1949</p>
                </h2>
                <div className="border-y border-black my-10 py-10 w-full space-y-4 text-justify"
                     dangerouslySetInnerHTML={{ __html: gContext.t('about.content') }}></div>
            </div>

            <ContributiFiscali/>
            <Help/>
            <Partners/>
        </MainLayout>
    )
}

export default About

export const Head = ({pageContext}) => (
    <SeoComponent pageContext={pageContext} />
)